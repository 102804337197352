<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Fabricacion </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <BuscarProducto
                  :label="`Producto`"
                  @GetProducto="GetProducto"
                  ref="RefProducto"
                  :filtros="{ id_puesto: store.state.puestoSelect.id_puesto }"
                  :rules="reglas.requerido"
                />
                <SelectAlmacen
                  class="mt-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.almacen_origen" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
                  :label="'Almacen Origen'"
                  :rules="reglas.requerido"
                />
                <SelectAlmacen
                  class="mt-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.almacen_destino"
                  :label="'Almacen Destino'" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
                  :rules="reglas.requerido"
                />
                <v-textarea
                  :rules="reglas.requerido"
                  v-model="datos.descripcion"
                  class="mt-4"
                  label="Descripcion"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" lg="6">
                <DatePicker
                  :rules="reglas.requerido"
                  v-model="datos.fecha_fabricacion"
                  :dense="true"
                  :outlined="true"
                  :label="'Fecha Fabricacion'"
                />
                <DatePicker
                  :rules="reglas.requerido"
                  v-model="datos.fecha_vencimiento"
                  :dense="true"
                  :outlined="true"
                  :label="'Fecha Vencimiento'" 
                />
                <v-text-field
                  :value="datos.costo"
                  prefix="$"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  label="Costo"
                ></v-text-field>
                <v-text-field
                  prefix="$"
                  :value="datos.costo_total"
                  readonly
                  outlined
                  dense
                  class="mt-4"
                  hide-details="auto"
                  label="Costo Total"
                ></v-text-field>
                <v-text-field
                  :suffix="datos.producto.unidad_medida.descripcion"
                  v-model="datos.cantidad"
                  outlined
                  dense
                  class="mt-4"
                  hide-details="auto"
                  label="Cantidad"
                  @keyup="calcularCosto()"
                  :rules="reglas.numerico_requerido_m_cero"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-subtitle> Detalle </v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="formDetalle" lazy-validation>
        <v-row class="mb-4 mt-2">
          <v-col cols="12">
            <BuscarProducto
              :label="`Producto detalle`"
              @GetProducto="GetProductoDetalle"
              ref="RefProductoDetalle"
              :filtros="{ id_puesto: store.state.puestoSelect.id_puesto }"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Producto</th>
              <th class="text-left">Cantidad</th>
              <th class="text-left">Costo Und</th>
              <th class="text-left">Costo Total</th>
              <th class="text-left">-</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datos.productos" :key="index">
              <td>
                {{ item.producto.id }}
              </td>
              <td>
                <strong> {{ item.producto.nombre }} </strong> <br />
                <small>{{ item.producto.unidad_medida.descripcion }}</small>
              </td>
              <td>
                <v-text-field
                  style="width: 100px"
                  class="mt-1"
                  hide-details=""
                  v-model="item.cantidad"
                  dense
                  outlined
                  :suffix="` ${item.producto.unidad_medida.unidad}`"
                  @keyup="calcularCosto()"
                ></v-text-field>
              </td>

              <td>{{ item.costo.toFixed(2) }}</td>
              <td>{{ (item.costo * item.cantidad).toFixed(2) }}</td>
              <td>
                <v-btn icon color="error" small @click="eliminarProdcuto(item)">
                  <v-icon small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <small v-if="datos.productos.length == 0" class="error--text">Debe tener por lo menos 1 ingrediente</small>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProductoExistencia.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectAlmacenJson from '@/views/sistema/inventario/maestro/almacen/componentes/SelectJson.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import { mdiInformation, mdiDelete, mdiPencil } from '@mdi/js'
import FabricacionServices from '@/api/servicios/inventario/FabricacionServices'

import SelectMotivo from './SelectMotivo.vue'
import moment from 'moment'
export default {
  watch: {
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
    'datos.fecha_fabricacion': function () {
      console.log('datos.fecha_fabricacion',this.datos.producto)
      if (this.datos.producto.dias_vencimiento_produccion) {
        this.datos.fecha_vencimiento =   moment(this.datos.fecha_fabricacion).add(this.datos.producto.dias_vencimiento_produccion, 'days').format('YYYY-MM-DD'); 
      } else {
        this.datos.fecha_vencimiento = this.datos.fecha_fabricacion
      }
    },
  },
  components: {
    DatePicker,
    SelectEstado,
    SelectAlmacen,
    BuscarProducto,
    TooltipInfo,
    SelectAlmacenJson,
    SelectMotivo,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const formDetalle = ref(null)

    const RefProductoDetalle = ref(null)
    const RefProducto = ref(null)

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      numerico_requerido: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
      ],
      numerico_requerido_m_cero: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
        v => !(v == 0) || 'No es numero valido 3',
      ],
    }
    const datosInit = {
      id: -1,
      almacen_origen: null,
      almacen_destino: null,
      descripcion: '',
      costo: 0,
      costo_total: 0,
      id_producto: '',
      id_puesto: '',
      cantidad: 0,
      cantidad_disponible: 0,
      productos: [],
      producto: {
        costo: 0,
        unidad_medida: {
          unidad: '',
          descripcion: '',
        },
      },
      id_empleado: null,
    }
    const itemAddInit = {
      producto: {},
      id_producto: null,
      cantidad: 0,
      costo: 0,
    }
    const filtrarAlmacenCosto = item => {
      let exis = {}
      exis = item.existencias.find(it => it.id_almacen == datos.value.almacen)

      return exis
    }
    const datos = ref(
      JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      ),
    )
    const limpiar = () => {
      datos.value = JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      )
      itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
      RefProducto.value.limpiar()
      form.value.resetValidation()
    }
    let editando = false

    const eliminarProdcuto = item => {
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
      calcularCosto()
    }
    const itemAdd = ref(JSON.parse(JSON.stringify(itemAddInit)))
    const cargandos = ref(false)

    const GetProducto = item => {
      console.log('GetProducto', item)
      if (editando == false) {
        if (item != undefined) {
          datos.value.id_producto = item.id
          datos.value.costo = item.costo
          datos.value.cantidad = 1
          datos.value.productos = []
          datos.value.producto = {
            id: item.id,
            nombre: item.nombre,
            costo: item.costo,
            existencias: item.existencias,
            unidad_medida: item.unidad_medida,
            dias_vencimiento_produccion: item.dias_vencimiento_produccion,
          }

          if (item.receta) {
            item.receta.receta_detalle.forEach(elem => {
              GetProductoDetalle({ ...elem.producto, cantidad: elem.cantidad })
            })
          }
          calcularCosto()
        }
      } else {
        editando = false
      }
    }

    const GetProductoDetalle = item => {
      console.log('GetProductoDetalle', item)
      if (item.id != datos.value.id_producto) {
        if (editando == false) {
          if (item != undefined) {
            itemAdd.value = {
              ...itemAddInit,
              id_producto: item.id,
              cantidad: item.cantidad ?? 0,
              costo: item.costo,
              producto: {
                id: item.id,
                nombre: item.nombre,
                costo: item.costo,
                existencias: item.existencias,
                unidad_medida: item.unidad_medida,
            dias_vencimiento_produccion: item.dias_vencimiento_produccion,
              },
            }
            agregarDetalle()
          }
        } else {
          editando = false
        }
      } else {
        store.commit('setAlert', {
          message: 'El producto no puede ser ingrediente de si mismo',
          type: 'warning',
        })
      }
    }
    const CargarEditar = item => {
      datos.value = {
        ...item,
        fecha_fabricacion: moment(item.fecha_fabricacion).format('YYYY-MM-DD'),
        fecha_vencimiento: moment(item.fecha_vencimiento).format('YYYY-MM-DD'),
      }
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      if (datos.value.productos.length == 0) {
        val = false
      } else {
        if (datos.value.productos.filter(it => !(it.cantidad > 0)).length > 0) {
          val = false
        }
      }

      return val
    }

    const agregarDetalle = () => {
      if (datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id) == undefined) {
        datos.value.productos.push({ ...itemAdd.value })
        itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
        RefProductoDetalle.value.limpiar()
      } else {
        store.commit('setAlert', {
          message: 'Ya existe el producto en la factura',
          type: 'warning',
        })
      }
    }
    const changeCantidades = (item, tipo) => {
      switch (tipo) {
        case 'cantidad':
          item.final = Number(item.actual) + Number(item.cantidad)
          break
        case 'final':
          item.cantidad = Number(item.final) - Number(item.actual)
          break
      }
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const calcularCosto = () => {
      let sum = 0

      datos.value.productos.forEach(element => {
        sum += element.costo * element.cantidad
      })

      datos.value.costo_total = sum
      datos.value.costo = sum / datos.value.cantidad
    }

    const cantidiadDisponible = () => {
      let sum = 0
      datos.value.productos.forEach(item => {
        sum += item.cantidad_real
      })

      return datos.value.cantidad - sum
    }

    const calcularCostoDetalle = () => {
      datos.value.productos.forEach(element => {
        element.cantidad_real = element.cantidad * element.relacion_costo
      })

      let total = datos.value.cantidad - cantidiadDisponible()

      let total_procentaje_editado = 0
      console.log('calcularCostoDetalle', total)
      datos.value.costo_real = datos.value.costo_total / total
      // calculo neto de todos los costos
      datos.value.productos.forEach(element => {
        element.costo = datos.value.costo_real * (element.porcentaje_costo / 100)
      })

      // recalcular costos 100% con diferencias de costo diferences a 100
      datos.value.productos.forEach(element => {
        if (element.porcentaje_costo != 100) {
          total_procentaje_editado += element.cantidad_real

          element.diferencia_costo = datos.value.costo_real - element.costo
          element.diferencia_costo_cantidad = element.diferencia_costo * element.cantidad_real
        } else {
          element.diferencia_costo = 0
        }
      })
      let total_cantidad_base = total - total_procentaje_editado
      //paso 2
      let sum_diferencias = 0
      datos.value.productos.forEach(element => {
        if (element.porcentaje_costo != 100) {
          element.diferencia_real = element.diferencia_costo_cantidad / total_cantidad_base
          sum_diferencias += element.diferencia_real
        }
      })

      datos.value.productos.forEach(element => {
        if (element.porcentaje_costo == 100) {
          element.costo = element.costo + sum_diferencias
        }

        element.costo_total = element.costo * element.cantidad_real
        element.costo_real = element.costo * element.relacion_costo
      })
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          FabricacionServices.FabricacionActualizar({
            ...datos.value,
            id_puesto: store.state.puestoSelect.id_puesto,
            cantidad_desperdicio: cantidiadDisponible(),
            porcentaje_desperdicio: ((cantidiadDisponible() / datos.value.cantidad) * 100).toFixed(2),
          })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}`,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: error,
            funcion: 'guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      store,
      GetProducto,
      GetProductoDetalle,
      RefProductoDetalle,
      itemAdd,
      formDetalle,
      agregarDetalle,
      RefProducto,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
      },
      eliminarProdcuto,
      funciones,
      filtrarAlmacenCosto,
      changeCantidades,
      calcularCosto,
      calcularCostoDetalle,
      cantidiadDisponible,
    }
  },
}
</script>