<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Generar etiqueta </v-card-title>

        <v-card-text>
          <v-select
            v-model="etiquetaSelect"
            dense
            outlined
            :items="etiquetas"
            item-text="descripcion"
            item-value="id"
            label="Etiqueta"
          ></v-select>
          <v-col cols="12">
            <v-card class="transparent">
              <v-card-text>
                <img class="white" ref="preview" width="100%" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="copias" dense outlined label="Cantidad"></v-text-field>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="info mr-1" small @click="generarPDF()">Generar PDF</v-btn>
          <v-btn color="secondary" small @click="dialog = false"> cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" v-show="false">
        <div style="height: calc(100vh - 200px)" ref="placeholder" />
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { Editor } from 'mini-canvas-editor'
import { MceImageJSON, MceRect, MceTextbox } from 'mini-canvas-core'
import 'mini-canvas-editor/css/editor.css'
import { onBeforeMount, ref } from '@vue/composition-api'
import jsPDF from 'jspdf'
import moment from 'moment'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
// demos

export default {
  watch: {
    etiquetaSelect: function (_new, _old) {
      const eti = this.etiquetas.find(el => (el.id = _new))
      this.StorageSet(eti.jsoncanvas)
      this.Iniciar(false)
      this.alto = eti.alto
      this.ancho = eti.ancho
      this.alto_pdf = eti.alto_pdf
      this.ancho_pdf = eti.ancho_pdf
      //this.parametros = eti.jsondatos
    },
  },

  methods: {
    async abirModal(id_tipo, _parametros) {
      this.dialog = true
      this.parametros = _parametros
      this.Iniciar(true)
      await this.cargarDatos(id_tipo)
      this.copias = 1
      if (this.etiquetaSelect) {
        const eti = this.etiquetas.find(el => (el.id = this.etiquetaSelect))
        this.StorageSet(eti.jsoncanvas)
        this.Iniciar(false)
        this.alto = eti.alto
        this.ancho = eti.ancho
        this.alto_pdf = eti.alto_pdf
        this.ancho_pdf = eti.ancho_pdf
      }
    },

    CmToPx(cm, pp = 72) {
      const px = cm * pp * 0.393701

      return px
    },
    PxToCm(px, pp = 72) {
      const cm = px / pp / 0.393701

      return cm
    },
    PxToPt(px, pp = 72) {
      const pt = (px / 96) * pp

      return pt
    },
    PtToPx(pt, pp = 72) {
      const px = (pt / pp) * 96

      return px
    },
    async generarPDF() {
      await this.reloadPreview()
      const imagen = this.$refs.preview.src
      console.log('pdf: ', this.$refs.preview.src)
      //const datos = this.editor.toJSON()
      const pdf = new jsPDF('l', 'cm', [this.PxToCm(this.ancho_pdf, 300), this.PxToCm(this.alto_pdf, 300)], true)
      /*const pdf = new jsPDF({
        unit: 'px', // set the units of measurement to px
        format: [this.ancho_pdf, this.alto_pdf], // set the 'paper' size
        userUnit: 72, // set the DPI here. Web uses 72 but you can change to 150 or 300
        orientation: 'l',
      })*/
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, 0, this.PxToCm(this.ancho_pdf, 300), this.PxToCm(this.alto_pdf, 300), 'F')
 
      pdf.addImage(imagen, 'jpg', 0, 0, this.PxToCm(this.ancho, 300), this.PxToCm(this.alto, 300))

      pdf.save(`asass.pdf`)
    },
    async cargarDatos(id_tipo) {
      await MaestroService.etiquetasListar({ id_tipo })
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            console.log(response.data)
            this.etiquetas = response.data.datos
          }
        })
        .catch(error => {})
    },
    guardarEtiqueta() {
      const datos = {
        id: this.etiquetaSelect,
        jsoncanvas: this.StorageTryGet(),
      }

      MaestroService.etiquetasActualizar(datos)
        .then(response => {
          if (response.data.mensaje == 'GUARDADO CON EXITO') {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: `Hubo un error: ${error}`,
            type: 'waring',
          })
        })
    },
    agregarTexto(texto, label) {
      this.editor.add(
        new MceTextbox(texto, {
          fontSize: 20,
          left: 0,
          top: 0,
          width: 200,
          maxHeight: 60,
          label,
        }),
      )
      this.dialog = false
    },
    agregarLogo() {
      this.editor.add(
        new MceRect({
          fill: '#80a30b',
          left: 0,
          top: 0,
          width: 260,
          height: 100,
          label: '$logo',
        }),
      )
      this.dialog = false
    },
    async Iniciar(reset) {
      const placeholder_ = document.getElementById('placeholder')
      const preview_ = document.getElementById('preview')

      const saved = this.StorageTryGet()

      if (saved && !reset) {
        if (this.editor) {
          this.editor.destroy()
        }

        this.editor = await Editor.createFromJSON(saved, this.$refs.placeholder, {})
      } else {
        if (this.editor) {
          this.editor.destroy()
        }
        this.editor = Editor.createBlank(this.$refs.placeholder, this.ancho * 2, this.alto * 2, {})
      }

      this.editor.onChanged.subscribe(() => {
        const png = this.editor.render().toDataURL('image/JPEG')
        //this.$refs.preview.src = png

        const json = this.editor.toJSON()
        this.StorageSet(json)
        this.reloadPreview()
      })
      this.reloadPreview()
    },
    async reloadPreview() {
      const canvas = await this.editor.cloneToStaticCanvas()
      const replacer = canvas.getReplacer()
      const layers = canvas.getLayers()

      layers.forEach(layer => {
        if (layer.type === 'textbox') {
          const text = replacer.getText(layer)

          let newText = text
          newText = this.cambiarTextoParametros(newText)

          if (newText !== text) {
            replacer.replaceText(layer, newText)
          }
        }
        if (layer.type === 'rect' && layer.name === '$logo') {
          replacer.replaceRectToImage(layer, store.state.configuracionGeneral.logo_empresa_2, 'fit')
        }
      })

      this.$refs.preview.src = canvas.exportToDataURL('JPEG')

      return canvas.exportToDataURL('JPEG')
    },
    StorageTryGet() {
      const raw = localStorage[this.localStorageKey]
      return raw ? JSON.parse(raw) : undefined
    },
    findObjectByLabel(obj, label) {
      if (label.includes('.')) {
        let array = label.split('.')
        const primero = array[0]
        array = array.splice(1).join('.')
        var foundLabel = findObjectByLabel(obj[primero], array)
        if (foundLabel) {
          return foundLabel
        }
      } else {
        if (obj != null) {
          return obj[label]?.toString()
        } else {
          return ''
        }
      }
    },
    StorageSet(json) {
      localStorage[this.localStorageKey] = JSON.stringify(json)
    },

    StorageClear() {
      localStorage.removeItem(this.localStorageKey)
    },
    cambiarTextoParametros(valor) {
      let newText = valor

      this.listarParametros(this.parametros).forEach(item => {
        newText = newText.replace(`$${item.id}/$`, item.valor)
      })
      /*
        for (const property in this.parametros) {
          console.log(`${property}: ${this.parametros[property]}`)
          newText = newText.replace(`$${property}`, this.parametros[property])
        }
  */
      return newText
    },
    generarRandom(cadena) { 
      const cadenaUnica = cadena

      // Calcula un hash (por ejemplo, usando MD5 o SHA-256)
      // Aquí utilizaremos una función simple para demostración
      let hash = 0
      for (let i = 0; i < cadenaUnica.length; i++) {
        hash = (hash << 5) - hash + cadenaUnica.charCodeAt(i)
      }

      // Asegúrate de que el hash sea positivo y tenga 6 dígitos
      const codigoUnico = Math.abs(hash % 1000000)
        .toString()
        .padStart(6, '0')
      return codigoUnico
    },
    listarParametros(objeto, arrayParametos = [], property_padre = '') {
      for (const property in objeto) {
        if (typeof objeto[property] === 'object' && !Array.isArray(objeto[property]) ){
          this.listarParametros(objeto[property], arrayParametos, property)
        } else {
          arrayParametos.push({
            id: this.generarRandom((property_padre != '' ? property_padre + '-' : '') + property),
            text: (property_padre != '' ? property_padre + '-' : '') + property,
            valor: objeto[property],
          })
        }
      }

      return arrayParametos
    },
  },
  data() {
    return {
      copias: 1,
      etiquetaSelect: null,
      etiquetas: [],
      localStorageKey: 'mceTemplateCreator_v2',
      editor: null,
      alto: 230,
      ancho: 410,
      id_tipo: null,
      alto_pdf: 230,
      ancho_pdf: 410,
      dialog: false,
      parametros: {
        numero_documento: 'DOC-000-01',
        titulo: 'FOODKART',
        fecha_elaboracion: moment().format('DD-MM-YYYY'),
        fecha_vencimiento: moment('20240220').format('DD-MM-YYYY'),
        almacen: 'PRODUCCION',
        producto: 'ALAS MARINADAS RACION DE 5/C',
        elaborador_por: 'ANGEL LABORI',
      },
    }
  },
}
</script>
  