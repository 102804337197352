
<template>
  <div>
    <v-card>
      <v-card-subtitle>Lista </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="filtro.valor"
              label="numero documento"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <SelectAlmacen
              v-model="filtro.almacen"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
            />
          </v-col>

          <v-col cols="12" md="3">
            <BuscarEmpleado @Getdatos="GetdatosEmpleado" :label="`Empleado`" />
          </v-col>
          <v-col cols="12" md="3">
            <SelectEstado
              v-model="filtro.estado"
              :dense="true"
              :outlined="true"
              :add="false"
              :padre="10"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_fabricacion"
              :dense="true"
              :outlined="true"
              :label="'Fecha Factura'"
            />
          </v-col>
          <v-col cols="12" md="3">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_creacion"
              :dense="true"
              :outlined="true"
              :label="'Fecha Creacion'"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small @click="cargar()" :loading="cargandoDatos" class="primary"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-progress-linear height="1" v-if="cargandoDatos" indeterminate></v-progress-linear>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Numero Documento</th>
                <th class="text-left">Area</th>
                <th class="text-left">Almacen</th>
                <th class="text-left">Producto</th>
                <th class="text-left">Costo</th>
                <th class="text-left">Cantidad</th>
                <th class="text-left">Empleado</th>
                <th class="text-left">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in Lista" :key="index">
                <td>
                  <small>{{ item.numero_documento }}</small>
                  <br />
                  <small>{{ funciones.formatoFecha(item.fecha_fabricacion, 1) }}</small>
                  <br />
                  <small>{{ funciones.formatoFecha(item.fecha_creacion, 3) }}</small>
                </td>
                <td>
                  <small>{{ item.puesto.nombre }}</small>
                </td>
                <td>
                  <small>{{ item.almacen_origen.descripcion }}</small>
                  <br />
                  <small>{{ item.almacen_destino.descripcion }}</small>
                </td>
                <td>
                  <small>{{ item.producto.nombre }}</small>
                  <br />
                  <small>{{ item.producto.unidad_medida.descripcion }}</small>
                </td>
                <td>
                  <small>{{ item.almacen_origen.descripcion }}</small>
                  <br />
                  <small>{{ item.almacen_destino.descripcion }}</small>
                </td>
                <td>
                  <small>Cantidad: {{ item.cantidad_total.toFixed(2) }}</small>
                  <br />
                  <small>Ingredientes: {{ item.productos.length }}</small>
                </td>

                <td>
                  <v-chip x-small :color="colorEstatus(item.estado.id)">{{ item.estado.descripcion }}</v-chip>
                </td>

                <td>
                  <BtnPdfCompra :id="item.id" :tipoBtn="2" />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="GetVer(item)">
                        <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                  <br />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        :disabled="item.estado.id == 62"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="GetAnular(item)"
                      >
                        <v-icon color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Anular</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        :disabled="item.estado.id == 62"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="AbrirEtiquetas(item)"
                      >
                        <v-icon  >{{ icons.mdiBarcodeScan }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Generar Etiqueta</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
            <tbody></tbody>
          </template>
        </v-simple-table>
        <div class="text-right">
          <span class="pt-4 mr-2">Total Registros: {{ TotalRow }}</span>
          <br />
          <v-pagination :disabled="cargandoDatos" v-model="Pagina" :length="Paginas" :total-visible="7"></v-pagination>
        </div>
      </v-card-text>
      <ModalView @GetEditar="GetEditar" @GetAnular="GetAnular" ref="ModalViewCompraRef"></ModalView>
      <ModalGenerarEtiquetas ref="ModalGenerarEtiquetasRef" />
    </v-card>
  </div>
</template>
  
<script>
import FabricacionServices from '@/api/servicios/inventario/FabricacionServices'
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import BuscarEmpleado from '@/views/sistema/administracion/archivo/empleado/components/BuscarEmpleado.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import DatePicker from '@/components/DatePicker.vue'
import ModalView from './ModalView.vue'
import BtnPdfCompra from './BtnPdf.vue'
import ModalGenerarEtiquetas from '@/views/sistema/administracion/archivo/etiquetas/componentes/ModalGenerarEtiquetas.vue'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
  mdiBarcodeScan
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import funciones from '@/funciones/funciones'
import Vue from 'vue'
export default {
  components: {
    SelectAlmacen,
    BuscarEmpleado,
    SelectEstado,
    DatePicker,
    ModalView,
    BtnPdfCompra,
    ModalGenerarEtiquetas
  },
  watch: {
    '$store.state.puestoSelect.id_puesto': function (news, olds) {
      if (news != olds) {
        this.cargarInicio()
      }
    },
  },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(5)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const ModalViewCompraRef = ref(null)
    const filtroInit = {
      id_empleado: null,
      id_almacen: null,
      estado: null,
      id_puesto: null,
      fecha_fabricacion: null,
      fecha_creacion: null,
      valor: '',
    }
    const anulando = ref(false)
    const colorEstatus = item => {
      switch (item) {
        case 61:
          return 'success'
          break
        case 62:
          return 'error'
          break
        default:
          return 'default'
          break
      }
    }
    const GetdatosProveedor = datos => {
      if (datos) {
        filtro.value.id_proveedor = datos.id
      } else {
        filtro.value.id_proveedor = null
      }
    }
    const ModalGenerarEtiquetasRef = ref()
    const AbrirEtiquetas = item => {
      console.log( ModalGenerarEtiquetasRef.value)
      ModalGenerarEtiquetasRef.value.abirModal(1, item)
    }

    const GetAnular = item => {
      if (item.estado.id != 62) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere anular la compra? </h3>' + `<p>${item.numero_documento ?? item.id}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return FabricacionServices.anularFabricacion({ id: item.id, id_empleado: store.state.user.id_empleado })
              .then(response => {
                if (response.data.mensaje == 'ANULADO CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          anulando.value = false
        })
      }
    }
    const GetdatosEmpleado = datos => {
      if (datos) {
        filtro.value.id_empleado = datos.id
      } else {
        filtro.value.id_empleado = null
      }
    }
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    const GetEditar = item => {
      console.log(item)
      context.emit('GetEditar', { ...item })
    }
    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })
    const GetVer = item => {
      ModalViewCompraRef.value.abrir(item.id)
    }
    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 5
      cargar()
    }
    const cargar = () => {
      try {
        cargandoDatos.value = true
        FabricacionServices.fabricacionListarPaginado({
          ...filtro.value,
          limit: Limit.value,
          pagina: Pagina.value - 1,
          id_puesto: store.state.puestoSelect.id_puesto,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos.datos
              Paginas.value = response.data.datos.paginado.paginas
              TotalRow.value = response.data.datos.paginado.registros
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
        mdiBarcodeScan
      },
      search,
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      filtro,
      store,
      funciones,
      colorEstatus,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      GetdatosEmpleado,
      GetdatosProveedor,
      ModalViewCompraRef,
      GetVer,
      GetAnular,
      anulando,
      AbrirEtiquetas,
      ModalGenerarEtiquetasRef,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  